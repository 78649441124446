






































































import Vue from 'vue';
import Component from 'vue-class-component';
import SurveyList from '@/components/surveys/SurveyList.vue';
import Page from '@/components/ui/Page.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import NewButton from '@/components/ui/NewButton.vue';
import {Route} from '@/enums';
import {authModule, userModule} from '@/store/modules';
import {UserResource} from '@/interfaces';

@Component({
  components: {NewButton, SurveyList, PageTitle, Page},
  props: [],
})
export default class Dashboard extends Vue {
  private tab = 0;

  get authUserId(): string | null {
    if (authModule.auth === null) {
      return null;
    }
    return String(authModule.auth.attributes.userId);
  }

  get user(): UserResource | null {
    if (this.authUserId === null) {
      return null;
    }
    return userModule.find(this.authUserId);
  }

  public async created(): Promise<void> {
    if (this.authUserId !== null) {
      await userModule.loadById(this.authUserId);
    }
  }

  public goToSurveyWizard() {
    this.$router.push({name: Route.SurveyWizard});
  }
}
