var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('PageTitle',[_vm._v("Dashboard")]),_c('NewButton',{attrs:{"horizontal":true,"label":"Create Survey"},on:{"click":_vm.goToSurveyWizard}}),_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("My Team's Active")]),_c('v-tab',[_vm._v("My Team's Completed")]),_c('v-tab',[_vm._v("ALL Communitech Active")]),_c('v-tab',[_vm._v("All Communitech Completed")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('h2',{staticClass:"mt-5"},[_vm._v("My Team's Active Surveys")]),_c('p',[_vm._v(" Lists all surveys currently active or in draft that were created by someone on my team. ")]),(_vm.user && _vm.tab === 0)?_c('SurveyList',{attrs:{"filters":{
          userGroupIds: _vm.user.attributes.userGroupIds.join(','),
          expired: 0,
        }}}):_vm._e()],1),_c('v-tab-item',[_c('h2',{staticClass:"mt-5"},[_vm._v("My Team's Completed Surveys")]),_c('p',[_vm._v(" Lists all surveys that have been run and then completed that were created by someone on my team. ")]),(_vm.user && _vm.tab === 1)?_c('SurveyList',{attrs:{"filters":{
          userGroupIds: _vm.user.attributes.userGroupIds.join(','),
          expired: '1',
          published: '1',
        }}}):_vm._e()],1),_c('v-tab-item',[_c('h2',{staticClass:"mt-5"},[_vm._v("ALL Communitech Active Surveys")]),_c('p',[_vm._v(" Lists all currently active or in draft surveys including reporting surveys that touch many teams (IE: collaborative surveys for Government reporting). ")]),(_vm.tab === 2)?_c('SurveyList',{attrs:{"filters":{expired: 0}}}):_vm._e()],1),_c('v-tab-item',[_c('h2',{staticClass:"mt-5"},[_vm._v("ALL Communitech Completed Surveys")]),_c('p',[_vm._v(" Lists all completed surveys including reporting surveys that touch many teams (IE: collaborative surveys for Government reporting). ")]),(_vm.tab === 3)?_c('SurveyList',{attrs:{"filters":{expired: '1', published: '1'}}}):_vm._e()],1)],1),_c('NewButton',{on:{"click":_vm.goToSurveyWizard}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }